import React, { useEffect, useState } from "react";
import UseActionMenu from "./actionsUserTable";
import { DataUsers } from "../../utils/Data";
// redux
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import {
  setPaginationProps,
  setSelectedRowsUser
} from "../../store/userActions";
import { GridNorows } from "@empreinte/components.grid-norows";
//import { DataGrid } from "@mui/x-data-grid";
import { Datagrid } from "@empreinte/components.datagrid";
import {  multiDelete } from "../../../../services/userAPI";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../utils/resize";
import { useMediaQuery } from "@material-ui/core";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {  GridValueGetterParams } from '@mui/x-data-grid';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function useDataTableMedia() {
  const { dataContent } = DataUsers();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const {t}=useTranslation()
  // get user data

  const listUsersProfile = useAppSelector(state=>state.UserReducer.listUsersProfile)
  const [selectedRow] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageSize, setPageSize] = useState<any>(DEFAULT_PAGE_SIZE);
  const [listUpdated, setLIstUpdated] = useState<any>([]);
  const [AbleToViewAdmin, setAbleToViewAdmin] = useState<any>(false);
  const [selectionModel,setSelectionModel]=useState<any>()
  const total=useAppSelector((state)=>state.UserReducer.totalRecords)
  const loading=useAppSelector((state)=>state.UserReducer.loading)


  //const [actionColumnView] = useActionMenuTable({ selectedRow, updateEntityPath , record });

  const dispatch = useDispatch();
  const windowSize = useWindowSize()
  const groups = useAppSelector((state)=>state.GroupeReducer.List.data)

  const hideColumn = (e:number) => {
    return  windowSize < e
 };

   const showPhoneColumns = useMediaQuery('(min-width:757px)');

  const handleIconClick = (e:any, y:any) => {
    dispatch(setPaginationProps({
      PaginationPropsNameChange: "order",
      PaginationPropsValueChange: e
    }))
    dispatch(setPaginationProps({
    PaginationPropsNameChange: "desc",
    PaginationPropsValueChange: y
  }))
  };
   const {userData} = useAppSelector(
      (state) => state.ReducerUserData
    );

useEffect(()=> {
    const listwithoutAdmin = listUsersProfile?.filter(({roles } : { roles: string[] }) => !roles?.includes("ROLE_SUPER_ADMIN"))
    setLIstUpdated(listwithoutAdmin)
    setAbleToViewAdmin(userData?.group?.id == 2 && window.process.env.CLIENT_NAME == "unesco")
},[listUsersProfile?.length,userData?.id])
  const updatedColumns = [
    {
      headerName: "ID",
      field: "id",
      key: 0,
      width: 150,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">ID</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: t("Profile.userName"),
      field: "username",
      key: 1,
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      hide:hideColumn(500),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Profile.userName")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(1,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(1,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: "Email",
      field: "email",
      key: 2,
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      hide:hideColumn(1150),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">Email</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(2,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(2,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName: t("Profile.Groupe"),
      field: "group.id",
      key: 3,
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      hide:hideColumn(900),
      valueGetter:(params:GridValueGetterParams)=> groups?.filter((group:any)=>group.id===params.row.group?.id)[0]?.name,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Profile.Groupe")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(3,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(3,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),
    },
    {
      headerName:t("Profile.lastNameUser"),
      field: "lastName",
      key: 4,
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      hide:hideColumn(900),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Profile.lastNameUser")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(4,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(4,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),    },
    {
      headerName: t("Profile.firstNameUser"),

      field: "firstName",
      key: 5,
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      hide:hideColumn(768) ,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t("Profile.firstNameUser")}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(5,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(5,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),   },
    {
      headerName: "Actions",
      key: "action",
      renderCell: (record: any, action: any) => {
        return <UseActionMenu  record={record} />;
      },
      field:"actions",
      width: 200,
      sortable: false,
    },
  ];

  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };
  interface Props{
    setCurrentPage:(e:any)=>any,
    currentPage:number
    setPageSize:(e:any)=>any,
    pageSize:number
  }

  
  const DataTable=({setCurrentPage,currentPage,setPageSize,pageSize}:Props)=>
    listUsersProfile?.length===0  || !listUsersProfile ?<div style={{height:"calc(98vh - 150px)"}}><GridNorows   content={t('Profile.noRows')} /></div>:
  <Datagrid
                   rows={AbleToViewAdmin ? listUpdated : listUsersProfile?.length
                       ? listUsersProfile
                       : []}
                   columns={updatedColumns as any}
                   //selectedUsers          
                   selectionModel={selectionModel}
                   checkboxSelect={(ids:any) => {
                       setSelectionModel(ids)
                     dispatch(setSelectedRowsUser(ids))
                   }}    
                   setCurrentPage={(e)=>{setCurrentPage(e)
                   dispatch(setPaginationProps({
                    PaginationPropsNameChange: "current",
                    PaginationPropsValueChange: e
                  }))}}
                   currentPage={currentPage}
                   setPageSize={(e)=>{setPageSize(e)
                    dispatch(setPaginationProps({
                      PaginationPropsNameChange: "pageSize",
                      PaginationPropsValueChange: e
                    }))
                  }}
                   pageSize={pageSize}
                   totalEntities={total&&total}
                   EntriesName={`${t("Profile.entries")}`}
                   OfPageName={`${t("Profile.of")}`}
                   ShowName={`${t("Profile.show")}`}
                   height={"calc(98vh - 150px)"}
                   checkboxSelection
                   loading={loading}
                   />

  return {
    DataTable,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
  };
}
export default useDataTableMedia;
