import React, { useState, useEffect } from "react";
import SearchIcon from "@material-ui/icons/Search";
import {
  Tooltip,
  Button,
  Paper,
  IconButton,
  InputBase,
  FormControl,
  TextField,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../redux/hooks";
import { DataIp } from "../../utils/Data";
import useDataTableMedia from "./DataTable";
import { useTranslation } from "react-i18next";
import { Hooks } from "../../utils/hooks";
import {SearchBar} from "@empreinte/components.search-bar"
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { Helpers } from "../../../user/utils/helpers";


const IpTable = ({setSearchText, searchText, setCurrentPage,currentPage,setPageSize,pageSize}:any) => {

  
  const { handleSearchRow,visibleMultiDeletePopup,handleCancelMultiDeletePopup,showModalMultiDeletePopup,DeleteIp }:any= Hooks();
  const { columns, data } = DataIp();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {acces_send} = Helpers()
  

  const valuePagination = useAppSelector(
    (state) => state.IPReducer.showDivsConditionsIP.elementsSelected
  );
  const { profiles_ip_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)

  const { DataTable} =
    useDataTableMedia({
      columns: columns,
      dataSource: data,
      updateEntityPath: "update-product",
    });


    const handleSearch = (e: any) => {
      setSearchText(e.target.value)
    }
    

  return (
    <div className="tableDatta" >
      <div className="tableDattaHeader">
        {valuePagination.length === 0 ? null : (
          <>
          <IconButton
          has-permission="parameters_theme_delete_multi"
          type="submit"
          aria-label="search"
          className="delete_number"
          style={{ display: "grid" }}
          onClick={showModalMultiDeletePopup}
          >
          <DeleteIcon className="gg" sx={{ color: "black"}} />
        </IconButton>

     
             <div className="suppText">{valuePagination.length}{" "}{t("Profile.élément(s) sélectionné(s)")}</div>
       
             </>
        )}
      <div className="FilterIp">

        <SearchBar 
          className="search-bar-Ip-main"
          name={"search"}
          placeholder="Search..."
          // onKeyDown={(e) => handleSearchRow(e)}
          setSearchText={handleSearch}
          value={searchText}
        ></SearchBar>
      </div> 
      </div>

      {(profiles_ip_module?.profiles_ip_list_dtable_action?.authorized || isSuperAdmin ) &&  
      <div has-permission="profiles_ip_list_dtable" className={"dataTable"}>
        <DataTable 
        searchText={searchText}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        />
         <ModalConfirm 
            open={visibleMultiDeletePopup}
            onClose={handleCancelMultiDeletePopup}
            title={t('Profile.DeleteConfirmMessageMultipleIp')}
            content={t("Profile.DeleteConfirmationContentText")}
            CancelBtnName={`${t('Profile.no')}`}
            onCancel={handleCancelMultiDeletePopup}
            ConfirmBtnName={`${t('Profile.yes')}`}
            onConfirm={()=>{acces_send(`${profiles_ip_module?.profiles_ip_delete_multi_action?.name}_action`);DeleteIp(valuePagination);handleCancelMultiDeletePopup()}}
           />
      </div>
         }  
      {/*./dataTable*/}
    </div>
  );
};
export default IpTable;
