import React, { useEffect, useState,useLayoutEffect } from "react";
import {
  Card,
  TextField,
  FormControl,
  MenuItem,
  Select,
  TextareaAutosize,
  InputLabel,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Hooks } from "../../utils/hooks";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks";
import {InputText} from "@empreinte/components.input-text"
import {TextArea} from "@empreinte/components.text-area"
import {SelectMultiple} from "@empreinte/components.select-multiple"
import {SelectList} from "@empreinte/components.select-list"
import { useDispatch } from "react-redux";
import {setAddGroupActions} from "../../store/groupActions"

interface Option {
  value: any;
  label: any;
}


const AddEditGroup = () => {
  const { t } = useTranslation();
  const { handleChangeInputs, handleChangeSelect, values} = Hooks();
  const [rol,setrol] = useState<any>([])
  const message = useAppSelector((state:any)=>state.GroupeReducer.showDivsConditionsGroup.message)
  const roles = useAppSelector((state:any)=> Object.entries(state.GroupeReducer.rolesSelect))
  const groups = useAppSelector((state:any)=> Object.entries(state.GroupeReducer.ListDrop.data))
  const parent = useAppSelector((state:any)=> state.GroupeReducer.addGroupConstraintsData.parentId)
  const [descError,setDescError] = useState(false)
  const [groupsAll,setGroupsAll] = useState<Option[]>([])
  const [selectedParent,setSelectedParent] = useState<string>(parent)
  const dispatch = useDispatch()
  useEffect(()=>{
    setrol(values.roles)
  },[values.roles])

  const returnDATA=()=>{
    let returnedData  : any = []
    roles.map((role) => (
      returnedData.push({label:role[0],Id:role[1]})
    ))
    return returnedData
  }
  useEffect(()=>{
    groups.map(obj => {
      const { id,name }:any = obj[1]
      setGroupsAll((prev)=>[...prev,{ value: id, label: name  }])
    })
  },[])

  useLayoutEffect(()=>{
    setSelectedParent(parent)
  },[parent])
 
  useEffect(()=>{
    if(values?.description?.length > 200){
      setDescError(true)
    }else{
      setDescError(false)
    }
  },[values.description])

    return (
    <div className={"divCardGroup"}>
                <span className="generalinfosGroup">{t("Profile.ipAdressInfosgroup")}</span>
                <InputText
                    className="select-again"
                    fullWidth
                    value={values.name}
                    style={{ width: '100%' }}
                    handleChange={handleChangeInputs}
                    labelInput={t("Profile.nameGroup")}
                    required
                    placeholder={t("Profile.nameGroup")}
                    name='name'
                ></InputText>
                {message?.length>0 && (
                  <p style={{ color: "red", fontSize: "12px",marginTop:0 }}>
                    {message?.length>0 && (message)}
                  </p>
                )}
                <SelectList
                    name="parentId"
                    style={{ width: `100%` }}
                    labelText={t("Profile.ParentGroup")}
                    value={selectedParent}
                    className="select-again"
                    data={groupsAll}
                    handleChange={(e:any) => {
                      setSelectedParent(JSON.parse(e.target.value))
                      dispatch(
                        setAddGroupActions({
                          addGroupConstraintsDataNameChange: "parentId",
                          addGroupConstraintsDataValueChange: e.target.value
                        })
                      );
                    }}
              ></SelectList>
                <SelectMultiple
                    listIds={rol || []}
                    dataList={returnDATA()}
                    handleChange={handleChangeSelect}
                    label={t("Profile.roles")}
                    required
                    placeHolder={t("Profile.roles")}
                    className="select-again"
                    style={{marginTop:0}}
              ></SelectMultiple>
                <TextArea
                    fullWidth
                    name='description'
                    placeholder={t('Profile.description')}
                    value={values.description}
                    style={{ width: '100%' ,marginTop:0}}
                    row={5}
                    maxRows={10}
                    labelInput={t('Profile.description')}
                    required
                    handleChange={handleChangeInputs}
                    className="select-again"
                ></TextArea>
                {descError && (
                  <p style={{ color: "red", fontSize: "12px",marginTop:0 }}>
                    {descError && t("Description is too long")}
                  </p>
                )}
    </div>
  );
};
export default AddEditGroup;
